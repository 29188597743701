




































import { Component, Vue } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import terminal from '@/store/terminal'
import { PaymentError } from '@/store/TerminalTypes'

const SVGSad = () => import('@/assets/images/svg/sad.svg?inline')

@Component({
  components: {
    SVGSad, QrcodeVue
  }
})
export default class CardPaymentError extends Vue {
  botUrl = 'https://t.me/WorkEat_bot'

  /**
   * Текст ошибок при мутации makePayment
   */
  get paymentErrors(): PaymentError | null {
    return terminal.getters.paymentErrors
  }
}
